import React, { createContext } from 'react';

import { BFFConsumerEnum, RequestStatus } from '../constants';

export type ProviderValue = {
	cache: Record<string, any>;
	cacheKey: string;
	status: RequestStatus | null;
	consumer: BFFConsumerEnum | null;
};

export const defaultProviderValue: ProviderValue = {
	cache: {},
	cacheKey: '',
	status: null,
	consumer: null,
};

type BFFContext = {
	value: ProviderValue;
	setValue: React.Dispatch<React.SetStateAction<ProviderValue>>;
};

export const BFFContext = createContext<BFFContext>({
	value: defaultProviderValue,
	setValue: () => {},
});

type BFFProviderProps = {
	consumer: BFFConsumerEnum;
	children: React.ReactNode;
};

export function BFFProvider({ consumer, children }: BFFProviderProps) {
	const [value, setValue] = React.useState<ProviderValue>({
		cache: {},
		cacheKey: consumer,
		consumer,
		status: null,
	});

	return <BFFContext.Provider value={{ value, setValue }}>{children}</BFFContext.Provider>;
}
