import { fg } from '@atlassian/jira-feature-gating';
import { getScopeIdentifier } from '@atlassian/jira-common-navigation/src/get-scope-identifier/index.tsx';
import type { ScopeIdentifier } from '@atlassian/jira-common-navigation/src/get-scope-identifier/types.tsx';
import { getBoardId } from '@atlassian/jira-router-resources-utils/src/utils/get-board-id/index.tsx';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import {
	// eslint-disable-next-line jira/restricted/@atlassian/react-resource-router
	createResource,
	type Match,
	type Query,
	useResource,
} from '@atlassian/react-resource-router';
import type { RouteName } from '@atlassian/jira-route-types/src/RouteName.tsx';
import { fetchProjectSidebarNavInfo } from '../../../services/sidebar/project-sidebar-navigation/main.tsx';
import type { ProjectSidebarNavInfo } from '../../../services/sidebar/project-sidebar-navigation/types.tsx';

export const getIdentifier = (
	routeName: RouteName,
	match: Match,
	query: Query,
): ScopeIdentifier => {
	/**
	 * `boardId` as an identifier should not be returned for JSM boards route,
	 * skip boards on `SERVICEDESK_BOARDS` or `SERVICEDESK_CALENDAR` route.
	 */
	if (
		routeName === 'servicedesk-boards' ||
		routeName === 'servicedesk-calendar' ||
		query['servicedesk.serviceDeskId'] ||
		((routeName === 'servicedesk-queues-custom-board' ||
			routeName === 'servicedesk-queues-custom-calendar' ||
			routeName === 'servicedesk-queues-practice-custom-board' ||
			routeName === 'servicedesk-queues-practice-custom-calendar' ||
			routeName === 'servicedesk-queues-views-config' ||
			routeName === 'servicedesk-practice-views-config') &&
			fg('jsm_views_inside_queues_-_main_flag'))
	) {
		const { projectKey, projectId } = getProjectKeyId(match, query);
		return getScopeIdentifier({ projectKey, projectId });
	}

	const { projectKey, projectId } = getProjectKeyId(match, query);
	const boardId = getBoardId(match, query);
	return getScopeIdentifier({ projectKey, projectId, boardId });
};

const getRouterKey = (routeName: RouteName, match: Match, query: Query): string => {
	const { identifierType, identifierValue } = getIdentifier(routeName, match, query);
	// Next-Gen software projects contain only one board. Hence, using the project key itself
	if (match.url.includes('/software/projects/')) {
		return `projectKey-${match.params.projectKey || ''}`;
	}
	return identifierType == null || identifierValue == null
		? 'none'
		: `${identifierType}-${identifierValue}`;
};

export const staticProjectSidebarNavigationResource = createResource<ProjectSidebarNavInfo | null>({
	type: 'NAVIGATION_SIDEBAR_JIRA_PROJECT',
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	getKey: ({ match, query, route: { name } }) => getRouterKey(name as RouteName, match, query),
	/**
	 * is called on resource's init fetch, or .refresh()
	 */
	getData: async ({ match, query, route: { name } }) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const scopeIdentifier = getIdentifier(name as RouteName, match, query);
		if (scopeIdentifier.identifierType == null || scopeIdentifier.identifierValue == null) {
			return null;
		}

		const navInfo = await fetchProjectSidebarNavInfo(scopeIdentifier);
		return navInfo;
	},
	maxAge: 5 * 60 * 1000,
});

export const useSidebarNavigationResource = () =>
	useResource(staticProjectSidebarNavigationResource);
