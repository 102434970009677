import { useEffect } from 'react';
import { type RefetchFnDynamic, useRelayEnvironment, fetchQuery } from 'react-relay';
import type { ConcreteRequest, OperationType } from 'relay-runtime';
import { fg } from '@atlassian/jira-feature-gating';
import { useGetCurrentUrl, useGetCurrentUrlPath } from '../use-get-current-url/index.tsx';

type OperationWithCloudIdAndCurrentURL = Omit<OperationType, 'variables'> & {
	readonly variables: { cloudId: string; currentURL: string; entityAriProjects: string };
};

type UseRefetchProjectsParams<
	RefetchContentKey extends Readonly<{ ' $data'?: unknown; ' $fragmentSpreads': unknown }>,
	QueryVariables,
> = {
	cloudId: string;
	isSelectedPath(menuId: string): boolean;
	menuId: string;
	refetch: RefetchFnDynamic<OperationWithCloudIdAndCurrentURL, RefetchContentKey>;
	QUERY: ConcreteRequest;
	queryVariables?: Partial<QueryVariables>;
};

export function useRefetchWhenIsSelectedOld<
	K extends Readonly<{ ' $data'?: unknown; ' $fragmentSpreads': unknown }>,
	QueryVariables,
>(params: UseRefetchProjectsParams<K, QueryVariables>) {
	const { cloudId, isSelectedPath, menuId, refetch, queryVariables, QUERY } = params;
	const environment = useRelayEnvironment();
	const currentURL = useGetCurrentUrl();

	useEffect(() => {
		if (isSelectedPath(menuId)) {
			fetchQuery(environment, QUERY, {
				cloudId,
				currentURL,
				...queryVariables,
			}).subscribe({
				complete: () => {
					refetch(
						{ cloudId, currentURL, ...queryVariables },
						{
							fetchPolicy: 'store-only',
						},
					);
				},
			});
		}
	}, [cloudId, isSelectedPath, menuId, refetch, currentURL, environment, queryVariables, QUERY]);
}

export function useRefetchWhenIsSelectedNew<
	K extends Readonly<{ ' $data'?: unknown; ' $fragmentSpreads': unknown }>,
	Q,
>(params: UseRefetchProjectsParams<K, Q>) {
	const { cloudId, isSelectedPath, menuId, refetch, queryVariables, QUERY } = params;
	const environment = useRelayEnvironment();
	const currentURL = useGetCurrentUrl();
	const currentURLPath = useGetCurrentUrlPath();

	useEffect(() => {
		if (isSelectedPath(menuId)) {
			fetchQuery(environment, QUERY, {
				cloudId,
				currentURL,
				currentURLPath,
				...queryVariables,
			}).subscribe({
				complete: () => {
					refetch(
						{ cloudId, currentURL, ...queryVariables },
						{
							fetchPolicy: 'store-only',
						},
					);
				},
			});
		}
	}, [
		cloudId,
		isSelectedPath,
		menuId,
		refetch,
		currentURL,
		currentURLPath,
		environment,
		queryVariables,
		QUERY,
	]);
}

export const useRefetchWhenIsSelected = <
	K extends Readonly<{ ' $data'?: unknown; ' $fragmentSpreads': unknown }>,
	QueryVariables,
>(
	params: UseRefetchProjectsParams<K, QueryVariables>,
) =>
	fg('plans_-_avoid_refetching_plans_nav_on_query_change')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useRefetchWhenIsSelectedNew(params)
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useRefetchWhenIsSelectedOld(params);
