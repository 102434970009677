import {
	createRouterSelector,
	useRouter,
	type Route,
	type JiraSpaRoute,
} from '@atlassian/react-resource-router';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types.tsx';
import type { RouteName } from '@atlassian/jira-route-types/src/RouteName.tsx';
import { getPreviousRouterState, type RouterState } from './common/utils/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	setMatchedRoute,
	getMatchedRoute,
	getPreviousSubProduct,
	setPreviousRouterState,
	getRouteName,
	getUserLocation,
	routeToUrlPath,
	setSubProduct,
	setPreviousSubProduct,
} from './common/utils';

export const useCurrentRoute = createRouterSelector<JiraSpaRoute>((state) => state.route);

// Use this only in situations where directly importing the route would cause a circular dependency
export const useRouteByName = createRouterSelector<JiraSpaRoute | null, RouteName>(
	(state, routeName) =>
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(state.routes.find(({ name }) => name === routeName) as JiraSpaRoute | undefined) ?? null,
);

export const toInvariantRoute = ({
	name,
	path,
	query = undefined,
	exact = undefined,
}: Route): InvariantRoute => ({ name, path, query, exact });

export const usePreviousRouterState = (): RouterState | null => {
	// Force updates whenever the current route changes
	useRouter();

	return getPreviousRouterState();
};
