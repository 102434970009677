import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { RequestStatus } from '../../constants';
import { BFFContext } from '../context';

export function useStatus() {
	const {
		setValue,
		value: { status },
	} = useContext(BFFContext);

	const [currentLoadingRequests, setCurrentLoadingRequests] = useState<string[]>([]);
	const isComplete = useMemo(() => status === RequestStatus.COMPLETE, [status]);
	const isError = useMemo(() => status === RequestStatus.ERROR, [status]);
	const isLoading = useMemo(() => status === RequestStatus.LOADING, [status]);
	const isLoaded = useMemo(() => status !== RequestStatus.LOADING, [status]);

	// Base the completion status value on whether or not all loading requests have
	// been completed
	useEffect(() => {
		if (!currentLoadingRequests.length && status === RequestStatus.LOADING) {
			// Ensure that the error state remains if it was previously set
			setValue((prev) => {
				if (prev.status === RequestStatus.ERROR) {
					return prev;
				}

				return { ...prev, status: RequestStatus.COMPLETE };
			});
		}
	}, [currentLoadingRequests, setValue, status]);

	const setStatus = useCallback(
		(newStatus: RequestStatus, integratingComponent?: string) => {
			if (newStatus === RequestStatus.LOADING && integratingComponent) {
				setCurrentLoadingRequests((prev) => {
					return [...new Set([...prev, integratingComponent])];
				});
			}

			// Track the loading requests that are currently in progress
			// and use the
			if (newStatus === RequestStatus.COMPLETE) {
				if (integratingComponent) {
					setCurrentLoadingRequests((prev) =>
						prev.filter((request) => request !== integratingComponent),
					);
				}
			}

			if (newStatus === RequestStatus.ERROR) {
				setCurrentLoadingRequests([]);
			}

			// Don't allow the status to be marked as anything other than ERROR
			// if an error is pre-existing
			setValue((prev) => {
				if (prev.status === RequestStatus.ERROR) {
					return prev;
				}

				return { ...prev, status: newStatus };
			});
		},
		[setValue],
	);

	return {
		status,
		isComplete,
		isError,
		isLoading,
		isLoaded,
		setStatus,
	};
}
