import type { JiraSpaRoute, Match, RouterContext } from '@atlassian/react-resource-router';
import type { RouteName } from '@atlassian/jira-route-types/src/RouteName.tsx';
import { ENTITY_ID, MENU_ID_OVERVIEWS } from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

const routes = new Set<RouteName>([
	'classic-business-overview-calendar',
	'classic-business-overview-redirect',
	'classic-business-overview-summary',
	'classic-business-overview-timeline',
]);

function convert(routeContext: RouterContext<JiraSpaRoute>): SelectedPath {
	const routeName = routeContext.route.name;
	const { match } = routeContext;
	const path: string[] = [MENU_ID_OVERVIEWS];
	return convertRouteToPath({ routeName, match, path });
}

function convertNew(args: { routeName: RouteName; match: Match }): SelectedPath {
	const { routeName, match } = args;
	const path: string[] = [MENU_ID_OVERVIEWS];
	return convertRouteToPath({ routeName, match, path });
}

function convertRouteToPath(args: {
	routeName: RouteName;
	match: Match;
	path: string[];
}): SelectedPath {
	const { routeName, match, path } = args;

	switch (routeName) {
		default: {
			const { overviewId } = match.params;
			overviewId && path.push(ENTITY_ID.OVERVIEW(overviewId));
		}
	}

	return path;
}

export const overviewsConverter = { routes, convert, convertNew };
