import type { Cards } from '../../../../common/types.tsx';
import {
	TAILORED_ONBOARDING_SUMMARY,
	TAILORED_ONBOARDING_LIST,
	TAILORED_ONBOARDING_TIMELINE,
	TAILORED_ONBOARDING_PAGES,
	TAILORED_ONBOARDING_FORM,
	TAILORED_ONBOARDING_INVITE,
	TAILORED_ONBOARDING_BOARD,
	TAILORED_ONBOARDING_CALENDAR,
	JWM_SAMPLE_DATA_TASK,
	TAILORED_ONBOARDING_CODE,
	TAILORED_ONBOARDING_BACKLOG,
} from '../../../../common/constants.tsx';
import { Experiment } from '../jsw/constants.tsx';
import { Experiments } from '../jwm/experiments.tsx';
import { Feature, TeamTypeWithPrefix } from './constants.tsx';

export const TAILORED_ONBOARDING_SINGLE_CONFIG: Cards = {
	[TAILORED_ONBOARDING_SUMMARY]: {
		position: {
			default: 200,
			conditional: [
				{
					condition: [TeamTypeWithPrefix.marketing],
					value: 400,
				},
			],
		},
		visibility: {
			default: false,
			conditional: [
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: false,
				},
				{
					condition: [TeamTypeWithPrefix.marketing],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.projectManagement],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.itSupport],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.operations],
					value: true,
				},
			],
		},
	},
	[TAILORED_ONBOARDING_TIMELINE]: {
		position: {
			default: 0,
			conditional: [
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						TeamTypeWithPrefix.software,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: 600,
				},
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: 500,
				},
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						TeamTypeWithPrefix.software,
					],
					value: 600,
				},
				{
					condition: [TeamTypeWithPrefix.marketing],
					value: 100,
				},
				{
					condition: [TeamTypeWithPrefix.productManagement],
					value: 200,
				},
				{
					condition: [TeamTypeWithPrefix.projectManagement],
					value: 100,
				},
				{
					condition: [TeamTypeWithPrefix.software],
					value: 400,
				},
				{
					condition: [TeamTypeWithPrefix.operations],
					value: 300,
				},
				{
					condition: [TeamTypeWithPrefix.everythingElse],
					value: 400,
				},
			],
		},
		visibility: {
			default: false,
			conditional: [
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						TeamTypeWithPrefix.software,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: true,
				},
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.marketing],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.productManagement],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.projectManagement],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.software],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.operations],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.everythingElse],
					value: true,
				},
			],
		},
	},
	[TAILORED_ONBOARDING_LIST]: {
		position: {
			default: 0,
			conditional: [],
		},
		visibility: {
			default: false,
			conditional: [
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: false,
				},
				{
					condition: [TeamTypeWithPrefix.marketing],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.design],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.productManagement],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.projectManagement],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.software],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.otherPersonal],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.itSupport],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.operations],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.everythingElse],
					value: true,
				},
			],
		},
	},
	[TAILORED_ONBOARDING_BACKLOG]: {
		position: { default: 0 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: true,
				},
			],
		},
	},
	[TAILORED_ONBOARDING_BOARD]: {
		position: {
			default: 100,
			conditional: [
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: 100,
				},
				{
					condition: [TeamTypeWithPrefix.design],
					value: 400,
				},
				{
					condition: [TeamTypeWithPrefix.itSupport],
					value: 300,
				},
				{
					condition: [TeamTypeWithPrefix.operations],
					value: 100,
				},
				{
					condition: [TeamTypeWithPrefix.everythingElse],
					value: 100,
				},
			],
		},
		visibility: {
			default: false,
			conditional: [
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.design],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.software],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.otherPersonal],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.itSupport],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.operations],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.everythingElse],
					value: true,
				},
			],
		},
	},
	[TAILORED_ONBOARDING_CALENDAR]: {
		position: {
			default: 300,
			conditional: [
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: 300,
				},
				{
					condition: [TeamTypeWithPrefix.marketing],
					value: 100,
				},
				{
					condition: [TeamTypeWithPrefix.productManagement],
					value: 300,
				},
				{
					condition: [TeamTypeWithPrefix.projectManagement],
					value: 300,
				},
				{
					condition: [TeamTypeWithPrefix.software],
					value: 200,
				},
				{
					condition: [TeamTypeWithPrefix.itSupport],
					value: 500,
				},
				{
					condition: [TeamTypeWithPrefix.operations],
					value: 400,
				},
				{
					condition: [TeamTypeWithPrefix.everythingElse],
					value: 200,
				},
			],
		},
		visibility: {
			default: false,
			conditional: [
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						TeamTypeWithPrefix.software,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: false,
				},
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: true,
				},
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						TeamTypeWithPrefix.software,
					],
					value: false,
				},
				{
					condition: [TeamTypeWithPrefix.marketing],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.design],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.software],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.otherPersonal],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.itSupport],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.operations],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.everythingElse],
					value: true,
				},
			],
		},
	},
	[TAILORED_ONBOARDING_CODE]: {
		position: {
			default: 300,
		},
		visibility: {
			default: false,
			conditional: [
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						TeamTypeWithPrefix.software,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: true,
				},
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						TeamTypeWithPrefix.software,
					],
					value: true,
				},
			],
		},
	},
	[TAILORED_ONBOARDING_PAGES]: {
		position: {
			default: 300,
			conditional: [
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						TeamTypeWithPrefix.software,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: 400,
				},
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: 500,
				},
				{
					condition: [TeamTypeWithPrefix.projectManagement],
					value: 500,
				},
				{
					condition: [TeamTypeWithPrefix.software],
					value: 500,
				},
				{
					condition: [TeamTypeWithPrefix.itSupport],
					value: 500,
				},
				{
					condition: [TeamTypeWithPrefix.everythingElse],
					value: 500,
				},
			],
		},
		visibility: {
			default: false,
			conditional: [
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.marketing],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.design],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.productManagement],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.projectManagement],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.software],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.otherPersonal],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.itSupport],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.operations],
					value: false,
				},
				{
					condition: [TeamTypeWithPrefix.everythingElse],
					value: true,
				},
			],
		},
	},
	[TAILORED_ONBOARDING_FORM]: {
		position: {
			default: 500,
			conditional: [
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: 500,
				},
				{
					condition: [TeamTypeWithPrefix.marketing],
					value: 400,
				},
				{
					condition: [TeamTypeWithPrefix.design],
					value: 300,
				},
				{
					condition: [TeamTypeWithPrefix.productManagement],
					value: 300,
				},
				{
					condition: [TeamTypeWithPrefix.projectManagement],
					value: 300,
				},
				{
					condition: [TeamTypeWithPrefix.itSupport],
					value: 400,
				},
			],
		},
		visibility: {
			default: false,
			conditional: [
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.design],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.productManagement],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.projectManagement],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.software],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.otherPersonal],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.itSupport],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.operations],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.everythingElse],
					value: true,
				},
			],
		},
	},
	[TAILORED_ONBOARDING_INVITE]: {
		position: {
			default: 500,
			conditional: [
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: 700,
				},
				{
					condition: [
						TeamTypeWithPrefix.software,
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
					],
					value: 700,
				},
			],
		},
		visibility: {
			default: false,
			conditional: [
				{
					condition: [
						Experiment.tailoredOnboardingScrumAndSoftwareKanbanEligible,
						Feature.hasBacklog,
						Feature.hasSprint,
					],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.marketing],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.design],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.productManagement],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.projectManagement],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.software],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.otherPersonal],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.itSupport],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.operations],
					value: true,
				},
				{
					condition: [TeamTypeWithPrefix.everythingElse],
					value: true,
				},
			],
		},
	},
	[JWM_SAMPLE_DATA_TASK]: {
		position: { default: 600 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Experiments.isSampleData],
					value: true,
				},
			],
		},
	},
};
