/** @jsx jsx */
import { forwardRef, useEffect } from 'react';
import { ClassNames, cssMap, styled, jsx } from '@compiled/react';
import { ax } from '@compiled/react/runtime';
import urlParse from 'url-parse';
import { CustomItem, type CustomItemComponentProps } from '@atlaskit/side-navigation';
import { token } from '@atlaskit/tokens';
import { useRouter, Link as RouterLink, type LinkProps } from '@atlassian/react-resource-router';
import { useSidebarTheme } from '../../../controllers/sidebar-theme/index.tsx';
import type { MenuItemProps } from './types.tsx';
import { useOnClick } from './utils/on-click/main.tsx';

const cssVarPrefix = '--sidebar-menu-item';
const bgColorCssVar = `${cssVarPrefix}-bg-color`;
const bgColorHoverCssVar = `${cssVarPrefix}-bg-color-hover`;
const bgColorActiveCssVar = `${cssVarPrefix}-bg-color-active`;
const colorCssVar = `${cssVarPrefix}-color`;
const colorHoverCssVar = `${cssVarPrefix}-color-hover`;
const colorActiveCssVar = `${cssVarPrefix}-color-active`;
const boxShadowCssVar = `${cssVarPrefix}-box-shadow`;
const iconSecondaryColorHoverCssVar = `${cssVarPrefix}-icon-secondary-color-hover`;

const styles = cssMap({
	root: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Temporary workaround to make Emotion and Compiled specificity mesh until `@atlaskit/menu` is migrated to Compiled.
		'&&': {
			/* fixes CSS leaking from the monolith */
			'&:focus, &:visited, &:active': {
				textDecoration: 'inherit',
				outline: 0,
			},
			/* fixes CSS leaking from the monolith */
			'&:active': {
				backgroundColor: `var(${colorActiveCssVar}, ${token('color.background.selected.pressed')})`,
				boxShadow: token('elevation.shadow.overlay'),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
				'[data-item-title]': {
					color: `var(${colorActiveCssVar}, ${token('color.text')})`,
				},
			},
			'&:focus': {
				boxShadow: `var(${boxShadowCssVar})`,
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'[data-item-title]': {
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
			lineHeight: 1.2,
		},
		/* fixes CSS for react-beautiful-dnd */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'&[data-rbd-draggable-id]': {
			cursor: 'pointer',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'&[aria-current="page"]': {
			position: 'relative',
		},
	},
	selected: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'[data-item-title]': {
			color: token('color.text.selected'),
		},
	},
	themeColorDefault: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Temporary workaround to make Emotion and Compiled specificity mesh until `@atlaskit/menu` is migrated to Compiled.
		'&&': {
			color: `var(${colorCssVar})`,
			/* fixes CSS leaking from the monolith */
			'&:focus, &:visited': {
				color: `var(${colorCssVar})`,
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'[data-item-title]': {
			color: `var(${colorCssVar})`,
		},
	},
	themeColorHover: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Temporary workaround to make Emotion and Compiled specificity mesh until `@atlaskit/menu` is migrated to Compiled.
		'&&': {
			'&:hover': {
				color: `var(${colorHoverCssVar})`,
			},
		},
	},
	themeColorActive: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Temporary workaround to make Emotion and Compiled specificity mesh until `@atlaskit/menu` is migrated to Compiled.
		'&&': {
			'&:active': {
				color: `var(${colorActiveCssVar})`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
				'[data-item-elem-before]': {
					color: `var(${colorActiveCssVar})`,
				},
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'&[aria-current="page"]': {
			color: `var(${colorActiveCssVar})`,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'&[aria-current="page"]::before': {
			background: `var(${colorActiveCssVar})`,
		},
	},
	themeBgColorDefault: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Temporary workaround to make Emotion and Compiled specificity mesh until `@atlaskit/menu` is migrated to Compiled.
		'&&': {
			backgroundColor: `var(${bgColorCssVar})`,
		},
	},
	themeBgColorHover: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Temporary workaround to make Emotion and Compiled specificity mesh until `@atlaskit/menu` is migrated to Compiled.
		'&&': {
			'&:hover': {
				backgroundColor: `var(${bgColorHoverCssVar})`,
			},
		},
	},
	themeBgColorActive: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'&[aria-current="page"]': {
			backgroundColor: `var(${bgColorActiveCssVar})`,
		},
	},
	themeIconSecondaryColorHover: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'[data-item-elem-after]': {
			color: `var(${iconSecondaryColorHoverCssVar})`,
		},
	},
});

type WrapperComponentProps = CustomItemComponentProps &
	LinkProps & {
		legacyClassName?: string;
	};

const WrapperComponent = forwardRef<HTMLAnchorElement | HTMLButtonElement, WrapperComponentProps>(
	(
		{
			children,
			className,
			legacyClassName,
			href,
			to,
			target,
			// @ts-expect-error - TS2339 - Property 'useButtonLink' does not exist on type 'CustomItemComponentProps & AnchorHTMLAttributes<HTMLAnchorElement> & { children: ReactNode; ... 10 more ...; prefetch?: false | ... 2 more ... | undefined; } & { ...; }'.
			useButtonLink,
			...props
		},
		ref,
	) => {
		const commonProps = {
			...props,
			children,
			className: `${className ?? ''} ${legacyClassName ?? ''}`,
		};

		return href != null || to != null ? (
			<RouterLink
				{...commonProps}
				href={href}
				to={to}
				target={target}
				ref={ref}
				type={useButtonLink ? 'button' : 'a'}
			/>
		) : (
			// @ts-expect-error Type 'HTMLAnchorElement | HTMLButtonElement | null' is not assignable to type 'HTMLButtonElement | null'
			<ButtonItem {...commonProps} type="button" ref={ref} />
		);
	},
);

export const MenuItem = forwardRef<HTMLElement, MenuItemProps>(
	(
		{
			analytics,
			href = undefined,
			onClick,
			overrides,
			selectedOn,
			className,
			useButtonLink = false,
			...rest
		},
		ref,
	) => {
		const onClickHandler = useOnClick({ onClick, analytics });
		const [{ route, query, location }] = useRouter();
		let isSelected: boolean;

		// Currently, because the CMP project settings are not part of the SPA, the whole sidebar reloads everytime a menu item
		// is clicked -> As part of the Lunar Project Settings improvements, we're scrolling selected items into view once the
		// page reloads.
		//
		// We're setting a timeout to wait for the event loop to complete prior to scrolling.
		useEffect(() => {
			// @ts-expect-error - TS2339 - Property 'current' does not exist on type 'MutableRefObject<any> | ((instance: any) => void)'.
			if (isSelected && ref?.current != null) {
				setTimeout(() => {
					// using ref?.current doesn't seem to work specifically here
					// Flow complianed about this part here specifically :/
					ref &&
						// @ts-expect-error - TS2339 - Property 'current' does not exist on type 'MutableRefObject<any> | ((instance: any) => void)'.
						ref.current != null &&
						// @ts-expect-error - TS2339 - Property 'current' does not exist on type 'MutableRefObject<any> | ((instance: any) => void)'.
						ref.current.scrollIntoView({
							behavior: 'smooth',
							block: 'center',
						});
				});
			}
		}, [
			selectedOn,
			ref,
			// @ts-expect-error - Variable 'isSelected' is used before being assigned.
			isSelected,
		]);

		if (typeof selectedOn === 'function') {
			isSelected =
				route != null &&
				// @ts-expect-error - Property 'search' is missing in type 'URLParse' but required in type 'MatcherLocation'.
				selectedOn({ ...location, query }, urlParse(href || '/', true));
		} else {
			isSelected =
				Boolean(selectedOn) &&
				route != null &&
				selectedOn != null &&
				selectedOn.includes(route.name);
		}

		const theme = useSidebarTheme();
		const itemTheme = theme?.navigation?.item;
		const iconTheme = theme?.navigation?.icon;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const customProps: any = {
			...rest,
			component: WrapperComponent,
			href,
			isSelected,
			onClick: onClickHandler,
			overrides,
			ref,
			useButtonLink,
			'aria-current': isSelected ? 'page' : undefined,
			style: {
				[bgColorCssVar]: itemTheme?.background?.default ?? undefined,
				[bgColorHoverCssVar]: itemTheme?.background?.hover ?? undefined,
				[bgColorActiveCssVar]: itemTheme?.background?.active ?? undefined,
				[colorCssVar]: itemTheme?.color?.default ?? undefined,
				[colorHoverCssVar]: itemTheme?.color?.hover ?? undefined,
				[colorActiveCssVar]: itemTheme?.color?.active ?? undefined,
				[boxShadowCssVar]:
					itemTheme?.color?.active != null
						? `${itemTheme?.color?.active} 0 0 0 2px inset`
						: `${token('color.border.focused')} 0 0 0 2px inset`,
				[iconSecondaryColorHoverCssVar]: iconTheme?.secondary?.color?.hover ?? undefined,
			},
		};

		if (overrides?.CustomItem?.render) {
			return (
				<ClassNames>
					{({ css }) =>
						overrides?.CustomItem?.render({
							...customProps,
							className: ax([
								css(styles.root),
								isSelected && css(styles.selected),
								itemTheme?.color?.default && css(styles.themeColorDefault),
								itemTheme?.background?.default && css(styles.themeBgColorDefault),
								itemTheme?.background?.hover && css(styles.themeBgColorHover),
								itemTheme?.background?.active && css(styles.themeBgColorActive),
								itemTheme?.color?.hover && css(styles.themeColorHover),
								itemTheme?.color?.active && css(styles.themeColorActive),
								iconTheme?.secondary?.color?.hover && css(styles.themeIconSecondaryColorHover),
								className,
							]),
						})
					}
				</ClassNames>
			);
		}

		return (
			<CustomItem
				{...customProps}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/design-system/no-unsafe-style-overrides
				className={className}
				// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
				css={[
					styles.root,
					isSelected && styles.selected,
					itemTheme?.color?.default && styles.themeColorDefault,
					itemTheme?.background?.default && styles.themeBgColorDefault,
					itemTheme?.background?.hover && styles.themeBgColorHover,
					itemTheme?.background?.active && styles.themeBgColorActive,
					itemTheme?.color?.hover && styles.themeColorHover,
					itemTheme?.color?.active && styles.themeColorActive,
					iconTheme?.secondary?.color?.hover && styles.themeIconSecondaryColorHover,
				]}
			/>
		);
	},
);

// this should be same as ButtonItem in '@atlaskit/menu'
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonItem = styled.button({
	backgroundColor: 'transparent',
	border: 0,
	outline: 0,
	margin: 0,
	width: '100%',
});
