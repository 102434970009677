import type { JiraSpaRoute, Match, Query, RouterContext } from '@atlassian/react-resource-router';
import type { RouteName } from '@atlassian/jira-route-types/src/RouteName.tsx';
import {
	ENTITY_ID,
	MENU_ID_FILTERS,
	MENU_ID_FILTERS_SEARCH,
	MENU_ID_FILTERS_VIEW_ALL,
	MENU_ID_PROJECTS,
} from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

const routes = new Set<RouteName>([
	'filters-directory',
	'global-issue-navigator',
	'global-issue-navigator-browse-issue-redirect',
	'issue',
	'legacy-gin',
	'legacy-gin-issue',
	'legacy-gin-subscriptions',
]);

function convert(routeContext: RouterContext<JiraSpaRoute>): SelectedPath {
	const routeName = routeContext.route.name;
	const { query, match } = routeContext;
	const path: string[] = [MENU_ID_FILTERS];
	return convertRouteToPath({ routeName, match, query, path });
}

function convertNew(args: { routeName: RouteName; match: Match; query: Query }): SelectedPath {
	const { routeName, match, query } = args;
	const path: string[] = [MENU_ID_FILTERS];
	return convertRouteToPath({ routeName, match, query, path });
}

function convertRouteToPath(args: {
	routeName: RouteName;
	match: Match;
	query: Query;
	path: string[];
}): SelectedPath {
	const { routeName, match, query, path } = args;
	switch (routeName) {
		case 'filters-directory':
			path.push(MENU_ID_FILTERS_VIEW_ALL);
			break;

		case 'issue': {
			const filterId = query.filter || query.filterId;

			if (filterId) {
				path.push(ENTITY_ID.FILTER(filterId));
			} else if (match.params?.issueKey) {
				const [projectKey] = (match.params?.issueKey || '').split('-'); // For this route to match, the issueKey will never be blank
				return [MENU_ID_PROJECTS, ENTITY_ID.PROJECT(projectKey)];
			}
			break;
		}

		default: {
			const filterId = query.filter || query.filterId;

			// Search filters URL
			if (filterId === undefined) {
				path.push(MENU_ID_FILTERS_SEARCH);
			} else {
				// The rest of the URLs are using a filter ID.
				// Test this case: https://buglow-jsw.jira-dev.com/secure/ViewSubscriptions.jspa?filterId=10007
				path.push(ENTITY_ID.FILTER(filterId));
			}

			break;
		}
	}

	return path;
}

export const filtersConverter = { routes, convert, convertNew };
