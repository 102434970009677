/**
 * @generated SignedSource<<869d8adbfbb8b7597e0e0d350333ff88>>
 * @relayHash dfd06866fddd95df7034205febe8eb4e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9b239a41299b531cf29a09d8abbeb2cf530d1715b2ea22ad115bc7f31e5f68f9

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraNavigationItemTypeKey = "APP" | "APPROVALS" | "APPS" | "ARCHIVED_ISSUES" | "ATTACHMENTS" | "BACKLOG" | "BOARD" | "CALENDAR" | "CODE" | "COMPONENTS" | "CUSTOMER_SUPPORT" | "DEPENDENCIES" | "DEPLOYMENTS" | "DEVELOPMENT" | "FORMS" | "GET_STARTED" | "GOALS" | "INCIDENTS" | "ISSUES" | "LIST" | "ON_CALL" | "PAGES" | "PLAN_CALENDAR" | "PLAN_DEPENDENCIES" | "PLAN_PROGRAM" | "PLAN_RELEASES" | "PLAN_SUMMARY" | "PLAN_TEAMS" | "PLAN_TIMELINE" | "PROGRAM" | "QUEUE" | "RELEASES" | "REPORTS" | "REQUESTS" | "SECURITY" | "SHORTCUTS" | "SUMMARY" | "TEAMS" | "TIMELINE" | "%future added value";
export type JiraContainerNavigationQueryInput = {
  projectKeyQuery?: JiraContainerNavigationByProjectKeyQueryInput | null | undefined;
  scopeId?: string | null | undefined;
};
export type JiraContainerNavigationByProjectKeyQueryInput = {
  cloudId: string;
  projectKey: string;
};
export type mainSettingsNavigationQuery$variables = {
  first: number;
  input: JiraContainerNavigationQueryInput;
};
export type mainSettingsNavigationQuery$data = {
  readonly jira: {
    readonly containerNavigation: {
      readonly __typename: "JiraContainerNavigation";
      readonly navigationItems: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly typeKey: JiraNavigationItemTypeKey | null | undefined;
          };
        } | null | undefined>;
        readonly errors: ReadonlyArray<{
          readonly extensions: ReadonlyArray<{
            readonly statusCode: number | null | undefined;
          }> | null | undefined;
          readonly message: string | null | undefined;
        }> | null | undefined;
      } | null | undefined;
    } | {
      readonly __typename: "QueryError";
      readonly extensions: ReadonlyArray<{
        readonly statusCode: number | null | undefined;
      }> | null | undefined;
      readonly message: string | null | undefined;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
  };
};
export type mainSettingsNavigationQuery = {
  response: mainSettingsNavigationQuery$data;
  variables: mainSettingsNavigationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "typeKey"
},
v6 = {
  "kind": "ScalarField",
  "name": "message"
},
v7 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v8 = {
  "kind": "LinkedField",
  "name": "extensions",
  "plural": true,
  "selections": [
    (v7/*: any*/)
  ]
},
v9 = {
  "kind": "ScalarField",
  "name": "id"
},
v10 = [
  (v6/*: any*/),
  {
    "kind": "LinkedField",
    "name": "extensions",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      (v7/*: any*/)
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "mainSettingsNavigationQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v2/*: any*/),
                "kind": "LinkedField",
                "name": "containerNavigation",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/),
                      {
                        "args": (v4/*: any*/),
                        "concreteType": "JiraNavigationItemConnection",
                        "kind": "LinkedField",
                        "name": "navigationItems",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "RequiredField",
                            "field": {
                              "concreteType": "JiraNavigationItemEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "kind": "RequiredField",
                                  "field": {
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/)
                                    ]
                                  },
                                  "action": "THROW",
                                  "path": "jira.containerNavigation.navigationItems.edges.node"
                                }
                              ]
                            },
                            "action": "THROW",
                            "path": "jira.containerNavigation.navigationItems.edges"
                          },
                          {
                            "concreteType": "QueryError",
                            "kind": "LinkedField",
                            "name": "errors",
                            "plural": true,
                            "selections": [
                              (v6/*: any*/),
                              (v8/*: any*/)
                            ]
                          }
                        ]
                      }
                    ],
                    "type": "JiraContainerNavigation"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v8/*: any*/)
                    ],
                    "type": "QueryError"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.containerNavigation"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "mainSettingsNavigationQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "containerNavigation",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": (v4/*: any*/),
                    "concreteType": "JiraNavigationItemConnection",
                    "kind": "LinkedField",
                    "name": "navigationItems",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraNavigationItemEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v5/*: any*/),
                              (v9/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "concreteType": "QueryError",
                        "kind": "LinkedField",
                        "name": "errors",
                        "plural": true,
                        "selections": (v10/*: any*/)
                      }
                    ]
                  }
                ],
                "type": "JiraContainerNavigation"
              },
              {
                "kind": "InlineFragment",
                "selections": (v10/*: any*/),
                "type": "QueryError"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v9/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "9b239a41299b531cf29a09d8abbeb2cf530d1715b2ea22ad115bc7f31e5f68f9",
    "metadata": {},
    "name": "mainSettingsNavigationQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "a86c2910ccbf2af4328e8f1d666e319c";

export default node;
