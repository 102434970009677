import type { RouteName } from '@atlassian/jira-route-types/src/RouteName.tsx';

export const JWM_OVERVIEWS_ROUTE_SET = new Set<RouteName>([
	'classic-business-overview-summary',
	'classic-business-overview-calendar',
	'classic-business-overview-timeline',
	'classic-business-overview-redirect',
]);

export const CONNECT_ROUTES_SET = new Set<RouteName>([
	'connect-project',
	'connect-general',
	'connect-general-service-desk-project',
	'legacy-connect-project',
	'legacy-connect-general',
]);

export const JSM_BOARD_ROUTES_SET = new Set<RouteName>([
	'servicedesk-boards',
	'servicedesk-queues-views-config',
	'servicedesk-practice-views-config',
]);
