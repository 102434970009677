import {
	TAILORED_ONBOARDING_BOARD,
	TAILORED_ONBOARDING_CALENDAR,
	TAILORED_ONBOARDING_INVITE,
	TAILORED_ONBOARDING_LIST,
	TAILORED_ONBOARDING_PAGES,
	TAILORED_ONBOARDING_SUMMARY,
	TAILORED_ONBOARDING_TIMELINE,
	TAILORED_ONBOARDING_FORM,
	JWM_SAMPLE_DATA_TASK,
} from '../../../../../common/constants.tsx';
import type { Cards } from '../../../../../common/types.tsx';
import { TeamType } from '../constants.tsx';
import { Experiments } from '../../jwm/experiments.tsx';

export const OTHER_PERSONAL_VARIANTS: Cards = {
	[TAILORED_ONBOARDING_SUMMARY]: {
		position: { default: -1 },
		visibility: {
			default: false,
		},
	},
	[TAILORED_ONBOARDING_TIMELINE]: {
		position: { default: -1 },
		visibility: {
			default: false,
		},
	},
	[TAILORED_ONBOARDING_LIST]: {
		position: { default: 0 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [TeamType.otherPersonal],
					value: true,
				},
			],
		},
	},
	[TAILORED_ONBOARDING_BOARD]: {
		position: { default: 100 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [TeamType.otherPersonal],
					value: true,
				},
			],
		},
	},
	[TAILORED_ONBOARDING_CALENDAR]: {
		position: { default: 200 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [TeamType.otherPersonal],
					value: true,
				},
			],
		},
	},
	[TAILORED_ONBOARDING_PAGES]: {
		position: { default: 300 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [TeamType.otherPersonal],
					value: true,
				},
			],
		},
	},
	[TAILORED_ONBOARDING_FORM]: {
		position: { default: 400 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [TeamType.otherPersonal],
					value: true,
				},
			],
		},
	},
	[TAILORED_ONBOARDING_INVITE]: {
		position: { default: 500 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [TeamType.otherPersonal],
					value: true,
				},
			],
		},
	},
	[JWM_SAMPLE_DATA_TASK]: {
		position: { default: 600 },
		visibility: {
			default: false,
			conditional: [
				{
					condition: [Experiments.isSampleData],
					value: true,
				},
			],
		},
	},
};
