import { useRoute } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-route/index.tsx';
import type { RouteName } from '@atlassian/jira-route-types/src/RouteName.tsx';

const GENERAL_CONNECT_ROUTES = new Set<RouteName>([
	'connect-general',
	'connect-general-invalid',
	'legacy-connect-general',
	'connect-general-service-desk-project',
]);

const UNCERTAIN_APPS_SECTION_ROUTES = GENERAL_CONNECT_ROUTES;

const CERTAIN_APPS_SECTION_ROUTES = new Set<RouteName>(['forge-spa-global', 'marketplace-index']);

export const APPS_SECTION_ROUTES = new Set<RouteName>([
	...UNCERTAIN_APPS_SECTION_ROUTES,
	...CERTAIN_APPS_SECTION_ROUTES,
]);

/**
 * @returns true when the current route is a general Connect route.
 */
export function useIsGeneralConnectRoute() {
	const route = useRoute();

	return GENERAL_CONNECT_ROUTES.has(route.name);
}
