import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import {
	PREMIUM_EDITION,
	getEditionForProject,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import type { RouteName } from '@atlassian/jira-route-types/src/RouteName.tsx';

export const useJsmPremiumPillExperiment = (): {
	getIsEligibleForJsmPremiumPillExperiment: () => boolean;
	isOnCMDBPage: boolean;
} => {
	const appEditions = useAppEditions();
	const edition = getEditionForProject(SERVICE_DESK_PROJECT, appEditions);
	const { data: project } = useProjectContext();
	const isJSM = project?.projectType === SERVICE_DESK_PROJECT;
	const isSiteAdmin = useIsSiteAdmin();
	const route = useCurrentRoute();
	const isOnCMDBPage = isCMDBPage(route.name);

	const getIsEligibleForJsmPremiumPillExperiment = () => {
		if (edition !== PREMIUM_EDITION) {
			return false;
		}
		if (isOnCMDBPage && isSiteAdmin) {
			// only siteAdmins on CMDB pages are eligible
			return true;
		}
		if (!project) {
			return false;
		}
		if (!isJSM) {
			return false;
		}
		if (project.simplified) {
			// simplified project does not have the premium features
			return false;
		}
		const isProjectAdmin = !!project.isProjectAdmin;
		// finally check if they are an admin who can change features for the project
		return isProjectAdmin || isSiteAdmin;
	};

	return {
		getIsEligibleForJsmPremiumPillExperiment,
		isOnCMDBPage,
	};
};

// cleanup with jsm_premium_trial_pill_experiment
export const isCMDBPage = (routeName: RouteName): boolean => {
	return [
		'servicedesk-cmdb-assets',
		'servicedesk-cmdb-configure',
		'servicedesk-cmdb-object',
		'servicedesk-cmdb-object-schema',
		'servicedesk-cmdb-object-schema-configuration',
		'servicedesk-cmdb-object-type-configuration',
		'servicedesk-cmdb-assets-reports',
		'servicedesk-cmdb-assets-reports-default-redirect',
		'servicedesk-cmdb-assets-reports-unsubscribe',
		'servicedesk-cmdb-insight',
		'servicedesk-cmdb-configure',
		'servicedesk-cmdb-object',
		'servicedesk-cmdb-object-schema',
		'servicedesk-cmdb-object-schema-configuration',
		'servicedesk-cmdb-object-type-configuration',
	].includes(routeName);
};
