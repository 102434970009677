/**
 * @generated SignedSource<<237720e9da495b57098e8dfedbac128e>>
 * @relayHash 25e998e06ecb4c919b9b27d42a08c6e6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 85c74fa4445f0b978347448f869ea33f59b10d7bcac29036ea93ca86b2ebbe8f

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { JourneysPageQuery } from './JourneysPageQuery.graphql';

const node: PreloadableConcreteRequest<JourneysPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "85c74fa4445f0b978347448f869ea33f59b10d7bcac29036ea93ca86b2ebbe8f",
    "metadata": {},
    "name": "JourneysPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
