import type { JiraSpaRoute, Query, RouterContext } from '@atlassian/react-resource-router';
import type { RouteName } from '@atlassian/jira-route-types/src/RouteName.tsx';
import {
	ENTITY_ID,
	MENU_ID_OPERATIONS,
	MENU_ID_OPS_ALERTS,
	MENU_ID_OPS_ON_CALL,
	MENU_ID_OPS_OVERVIEW,
	MENU_ID_OPS_REPORTS,
} from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

const routes = new Set<RouteName>([
	'eoc-automations',
	'eoc-custom-roles',
	'eoc-global-alerts',
	'eoc-global-alert-detail',
	'eoc-global-group-detail',
	'eoc-global-reports',
	'eoc-global-report-dashboard',
	'eoc-heartbeats',
	'eoc-global-heartbeats',
	'eoc-maintenance',
	'eoc-on-call',
	'eoc-on-call-redirect',
	'eoc-overview',
	'eoc-overview-all-teams',
	'eoc-permissions',
	'eoc-reports',
	'eoc-reports-team',
	'eoc-reports-unsubscribe',
	'eoc-schedules',
	'eoc-team-alerts',
	'eoc-team-alert-detail',
	'eoc-team-alert-issue-syncing',
	'eoc-team-alert-issue-syncing-create',
	'eoc-team-alert-issue-syncing-detail',
	'eoc-team-group-detail',
	'eoc-team-integrations',
	'eoc-team-integration-create',
	'eoc-team-integration-detail',
	'eoc-team-integration-list',
	'eoc-team-integration-log',
	'eoc-team-policies',
	'eoc-who-is-on-call',
	'eoc-global-escalations',
]);

function convert(routeContext: RouterContext<JiraSpaRoute>): SelectedPath {
	const routeName = routeContext.route.name;
	const { query } = routeContext;
	const path: string[] = [MENU_ID_OPERATIONS];
	return convertRouteToPath({ routeName, query, path });
}

function convertNew(args: { routeName: RouteName; query: Query }): SelectedPath {
	const { routeName, query } = args;
	const path: string[] = [MENU_ID_OPERATIONS];
	return convertRouteToPath({ routeName, query, path });
}

function convertRouteToPath(args: {
	routeName: RouteName;
	query: Query;
	path: string[];
}): SelectedPath {
	const { routeName, query, path } = args;
	switch (routeName) {
		case 'eoc-who-is-on-call':
			path.push(MENU_ID_OPS_ON_CALL);
			break;

		case 'eoc-global-alerts':
		case 'eoc-global-alert-detail': {
			path.push(MENU_ID_OPS_ALERTS);
			const { query: alarmQueue = '', view } = query;
			view && path.push(ENTITY_ID.ALARM_QUEUE(alarmQueue));
			break;
		}

		case 'eoc-global-reports':
		case 'eoc-global-report-dashboard':
		case 'eoc-reports-unsubscribe':
			path.push(MENU_ID_OPS_REPORTS);
			break;

		case 'eoc-automations':
		case 'eoc-global-group-detail': // This might be wrong, but I can't see the feature.
		case 'eoc-heartbeats':
		case 'eoc-maintenance':
		case 'eoc-permissions':
		case 'eoc-on-call':
		case 'eoc-on-call-redirect':
		case 'eoc-overview':
		case 'eoc-overview-all-teams':
		case 'eoc-reports':
		case 'eoc-reports-team':
		case 'eoc-schedules':
		case 'eoc-team-group-detail':
		case 'eoc-team-alerts':
		case 'eoc-team-alert-detail':
		case 'eoc-team-policies':
		case 'eoc-team-alert-issue-syncing':
		case 'eoc-team-alert-issue-syncing-create':
		case 'eoc-team-alert-issue-syncing-detail':
		case 'eoc-team-integrations':
		case 'eoc-team-integration-create':
		case 'eoc-team-integration-detail':
		case 'eoc-team-integration-list':
		case 'eoc-team-integration-log':
			path.push(MENU_ID_OPS_OVERVIEW);
			break;

		default: {
			break;
		}
	}

	return path;
}

export const operationsConverter = { routes, convert, convertNew };
