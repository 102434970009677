import type { JiraSpaRoute, Match, RouterContext } from '@atlassian/react-resource-router';
import type { RouteName } from '@atlassian/jira-route-types/src/RouteName.tsx';
import { ENTITY_ID, MENU_ID_PLANS, MENU_ID_PLANS_VIEW_ALL } from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

const routes = new Set<RouteName>([
	'plans-directory',
	'software-advanced-roadmaps',
	'software-advanced-roadmaps-getting-started-redirect',
	'software-advanced-roadmaps-setup',

	'software-plan-calendar',
	'software-plan-dependencies',
	'software-plan-increment',
	'software-plan-increments',
	'software-plan-releases',
	'software-plan-summary',
	'software-plan-teams',
	'software-plan-timeline',
	'trashed-plan-direct-link',
	'software-plan-upsell',
]);

function convert(routeContext: RouterContext<JiraSpaRoute>): SelectedPath {
	const routeName = routeContext.route.name;
	const { match } = routeContext;
	const path: string[] = [MENU_ID_PLANS];
	return convertRouteToPath({ routeName, match, path });
}

function convertNew(args: { routeName: RouteName; match: Match }): SelectedPath {
	const { routeName, match } = args;
	const path: string[] = [MENU_ID_PLANS];
	return convertRouteToPath({ routeName, match, path });
}

function convertRouteToPath(args: {
	routeName: RouteName;
	match: Match;
	path: string[];
}): SelectedPath {
	const { routeName, match, path } = args;

	switch (routeName) {
		case 'plans-directory':
		case 'software-advanced-roadmaps-getting-started-redirect':
			path.push(MENU_ID_PLANS_VIEW_ALL);
			break;

		case 'trashed-plan-direct-link':
			break;

		default: {
			const { planId } = match.params;
			planId && path.push(ENTITY_ID.PLAN(planId));
		}
	}

	return path;
}

export const plansConverter = { routes, convert, convertNew };
